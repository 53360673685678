import { API, URL } from "src/config/api";

export const getHomeCategories = async (paginate) => {
  let url = `${URL.categories}/home?pageSize=${paginate}`;
  try {
    const response = await API.get(url);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const getData = async (obj) => {
  let url = URL.categories;
  if (obj) {
    let queryString = "";
    Object.entries(obj).map(
      ([key, value]) => (queryString += `&${key}=${value}`)
    );
    queryString = queryString.substring(1);
    queryString = `?${queryString}`;
    url += queryString;
  }

  try {
    const response = await API.get(url);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const getSingle = async (slug, obj) => {
  let url = `${URL.categories}/${slug}`;
  if (obj) {
    let queryString = "";
    Object.entries(obj).map(
      ([key, value]) => (queryString += `&${key}=${value}`)
    );
    queryString = queryString.substring(1);
    queryString = `?${queryString}`;
    url += queryString;
  }

  try {
    const response = await API.get(url);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const createData = async (data) => {
  try {
    const response = await API.post(`${URL.categories}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateData = async (id, data) => {
  try {
    const response = await API.put(`${URL.categories}/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateSpecials = async (data) => {
  try {
    const response = await API.put(`${URL.categories}/update/special`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteData = async (id) => {
  try {
    const response = await API.delete(`${URL.categories}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
