import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

//Global
import { globalStyles } from "./styles";

//Breakpoints
import { breakpoints } from "./foundations/breakpoints";

//Components
import { buttonStyles } from "./components/button";
import { inputStyles } from "./components/input";
import { linkStyles } from "./components/link";
import { containerStyles } from "./components/container";
import { selectStyles } from "./components/select";
import { textAreaStyles } from "./components/text-area";
import { textStyles } from "./components/text";
import { headingStyles } from "./components/heading";
import { tableStyles } from "./components/table";
import { formLabelStyles } from "./components/label";

export default extendTheme(
  //breakpoints
  { breakpoints },
  //global
  globalStyles,
  //components
  buttonStyles,
  inputStyles,
  linkStyles,
  containerStyles,
  selectStyles,
  textAreaStyles,
  textStyles,
  headingStyles,
  tableStyles,
  formLabelStyles,
  {
    components: {
      Steps,
    },
  }
);
