const routes = [
  {
    directory: "home",
    url: "",
    private: false,
    index: false,
  },
  {
    directory: "checkout",
    url: "checkout",
    params: ":cartId",
    private: false,
    index: false,
  },
  {
    directory: "payment",
    url: "payment",
    params: ":cartId",
    private: false,
    index: false,
  },
  {
    directory: "gdpr",
    url: "gdpr-compliance",
    private: false,
    index: false,
  },
  {
    directory: "privacy",
    url: "privacy-statement",
    private: false,
    index: false,
  },
  {
    directory: "payment-success",
    url: "payment-success",
    private: false,
    index: false,
  },
  {
    directory: "single-categorie",
    url: "categories",
    params: ":slug",
    private: false,
    index: false,
  },
  {
    directory: "search",
    url: "search",
    private: false,
    index: false,
  },
  {
    directory: "search-single",
    url: "search",
    params: ":type/:query",
    private: false,
    index: false,
  },
  {
    directory: "single-promotion",
    url: "promotions",
    params: ":id",
    private: false,
    index: false,
  },
  {
    directory: "single-promotion",
    url: "promotions",
    params: ":id/:slug",
    private: false,
    index: false,
  },
  {
    directory: "single-business",
    url: "businesses",
    params: ":id",
    private: false,
    index: false,
  },
  {
    directory: "single-business",
    url: "businesses",
    params: ":id/:slug",
    private: false,
    index: false,
  },
  {
    directory: "registration",
    url: "login",
    private: false,
    index: true,
  },
  {
    directory: "forgot-password",
    url: "forgot-password",
    private: false,
    index: true,
  },
  {
    directory: "reset-password",
    url: "reset-password",
    params: ":userId/:token",
    private: false,
    index: true,
  },
  {
    directory: "about-us",
    url: "about-us",
    private: false,
    index: true,
  },
  {
    directory: "contact-us",
    url: "contact-us",
    private: false,
    index: true,
  },
  {
    directory: "how-works",
    url: "how-it-works",
    private: false,
    index: true,
  },
  {
    directory: "dashboard",
    url: "dashboard",
    private: true,
    index: false,
    children: {
      users: {
        directory: "users",
        url: "users",
        key: "users",
        priority: 2,
      },
      permissions: {
        directory: "permissions",
        url: "permissions",
        key: "permissions",
        priority: 3,
      },
      roles: {
        directory: "roles",
        url: "roles",
        key: "roles",
        priority: 4,
      },
      categories: {
        directory: "categories",
        url: "categories",
        key: "categories",
        priority: 5,
      },
      promotions: {
        directory: "promotions",
        url: "promotions",
        key: "promotions",
        priority: 7,
      },
      collections: {
        directory: "collections",
        url: "collections",
        key: "collections",
        priority: 10,
      },
      attributes: {
        directory: "attributes",
        url: "attributes",
        key: "attributes",
        priority: 6,
      },
      profile: {
        directory: "profile",
        url: "profile",
        key: "profile",
        priority: 1,
      },
      businesses: {
        directory: "businesses",
        url: "businesses",
        params: ":status",
        key: "businesses",
        priority: 14,
      },
      currentInvoices: {
        directory: "current-invoices",
        url: "invoices/current",
        key: "currentInvoices",
        priority: 15,
      },
      invoices: {
        directory: "invoices",
        url: "invoices",
        key: "invoices",
        priority: 16,
      },
      subscriptions: {
        directory: "subscriptions",
        url: "subscriptions",
        key: "subscriptions",
        priority: 9,
      },
      comments: {
        directory: "comments",
        url: "comments",
        params: ":status",
        key: "comments",
        priority: 12,
      },
      carts: {
        directory: "carts",
        url: "carts",
        params: ":status",
        key: "carts",
        priority: 13,
      },
      soldPromotions: {
        directory: "sold-promotions",
        url: "sold-promotions",
        key: "soldPromotions",
        priority: 8,
      },
    },
  },
];

export default routes;
