import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  let url = URL.carts;

  try {
    const response = await API.get(url, { params: obj });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const createCart = async (data) => {
  try {
    const response = await API.post(URL.carts, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const getSingle = async (slug) => {
  try {
    const response = await API.get(`${URL.carts}/${slug}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const createData = async (data) => {
  try {
    const response = await API.post(`${URL.carts}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const submitVoucher = async (voucher, data = {}) => {
  try {
    const response = await API.post(`${URL.carts}/voucher/${voucher}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateData = async (id, data) => {
  try {
    const response = await API.put(`${URL.carts}/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteData = async (id) => {
  try {
    const response = await API.delete(`${URL.carts}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
