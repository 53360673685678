export const headingStyles = {
  components: {
    Heading: {
      defaultProps: {
        size: "md",
      },
      baseStyle: {
        fontFamily: "boldHeading",
      },
      sizes: {
        xs: { fontSize: "xs" },
        sm: { fontSize: "md" },
        md: { fontSize: "xl" },
        lg: { fontSize: "2xl" },
        xl: { fontSize: "3xl" },
        "2xl": { fontSize: "4xl" },
      },
    },
  },
};
