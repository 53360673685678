import { API } from "src/config/api";

const setAuthToken = (token) => {
  if (token) {
    API.defaults.headers.common["Authorization"] = `bearer ${token}`;
  } else {
    delete API.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
