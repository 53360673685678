import React from "react";
import { Flex, Container, Heading, Image, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { IMAGES } from "src/images";

const ErrorPage = ({ title }) => {
  return (
    <Container>
      <Flex
        flex={1}
        h="100vh"
        justify="center"
        align={"center"}
        flexDir="column"
      >
        <Image w="400px" mb="70px" src={IMAGES.empty} />
        <Heading mb="20px">
          {title || "The desired page was not found!"}
        </Heading>
        <NavLink to="/">
          <Button variant={"secondary"} w="200px" h="50px">
            Return To Home
          </Button>
        </NavLink>
      </Flex>
    </Container>
  );
};
export default ErrorPage;
