export const colors = {
  white: "#ffffff",
  black: "#000000",
  primary: {
    50: "#ffe3e8",
    100: "#ffb5be",
    200: "#fa8693",
    300: "#f65669",
    400: "#ef3b4f",
    500: "#d80d25",
    600: "#a9061c",
    700: "#790313",
    800: "#4b0009",
    900: "#1f0002",
    main: "#ef3b4f",
  },
  secondary: {
    50: "#dcf6ff",
    100: "#b0e0ff",
    200: "#80cbff",
    300: "#50b6fe",
    400: "#28a1fc",
    500: "#1788e3",
    600: "#0a6ab2",
    700: "#005087",
    800: "#002d4f",
    900: "#00101f",
    main: "#005087",
  },
  navy: { dark: "#212121", light: "#303030" },
  sky: { dark: "#dae9f7", light: "#eff7ff" },
  red: { dark: "#a42d44", light: "#ca2241" },
  green: { dark: "#11534d", light: "#2f726b" },
  yellow: { dark: "#8a8328", light: "#cabb00" },
  blue: { dark: "#205aaa", light: "#3182CE" },
  orange: { dark: "#9d7627", light: "#ce9d14" },
  darkGray: { dark: "#34495E", light: "#40617e" },
  lightGray: { dark: "#BEC3C7", light: "#EBF1F1" },
};
