import { combineReducers } from "redux";
import appReducer from "src/redux/app/reducers";
import userReducer from "src/redux/user/reducers";
import cartReducer from "src/redux/cart/reducers";

export default combineReducers({
  app: appReducer,
  user: userReducer,
  cart: cartReducer,
});
