//redux
import { SET_APP_DATA, SET_APP_LOADING } from "src/redux/constants";

import { setCurrentUser } from "src/redux/user/actions";
import { getCart } from "src/redux/cart/actions";

//services
import { getData as getPermissions } from "src/services/api/permissions";
import { getData as getRoles } from "src/services/api/roles";
import { getData as getCategories } from "src/services/api/categories";
import { getData as getAttributes } from "src/services/api/attributes";
import { getData as getBusinesses } from "src/services/api/businesses";

import { setApiHeaders } from "src/helper";

export const getAppData = (payload) => async (dispatch) => {
  let appData = {};
  const token = localStorage.getItem("userToken");
  setApiHeaders({ userId: null, role: null, path: "/" });
  if (token) {
    await dispatch(setCurrentUser(token))
      .then(async (user) => {
        if (user.role.value !== "user") {
          await getPermissions()
            .then((data) => (appData.permissions = data.permissions))
            .catch((err) => console.log(err));
          await getRoles()
            .then((data) => (appData.role = data.roles))
            .catch((err) => console.log(err));
        }
        await getAttributes()
          .then((data) => (appData.attributes = data.attributes))
          .catch((err) => console.log(err));
        await getBusinesses({ page: 1, pageSize: 100 })
          .then((data) => (appData.businesses = data.businesses))
          .catch((err) => console.log(err));
        await dispatch(getCart());
      })
      .catch((error) => {});
  }
  await getCategories({ isPublish: true })
    .then((data) => (appData.categories = data.categories))
    .catch((err) => console.log(err));
  dispatch({
    type: SET_APP_DATA,
    payload: appData,
  });
  dispatch({
    type: SET_APP_LOADING,
    payload: false,
  });
};
