export const IMAGES = {
  logoLight: require("src/images/files/logo-light.png"),
  logoDark: require("src/images/files/logo-dark.png"),
  empty: require("src/images/files/404.png"),
  offer: require("src/images/files/box.png"),
  registrationCover: require("src/images/files/registration-cover.jpg"),
  beauty: require("src/images/files/beauty.jpg"),
  food: require("src/images/files/food.jpg"),
  travel: require("src/images/files/travel.jpg"),
  goal: require("src/images/files/our-goal.png"),
  mission: require("src/images/files/our-mission.png"),
  values: require("src/images/files/our-values.png"),
  notify: require("src/images/files/notify.png"),
  emptyCart: require("src/images/files/emptyCart.png"),
  mapBg: require("src/images/files/mapBg.png"),
};
