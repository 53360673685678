import routes from "src/routes";

//icons
import { FiUsers, FiUser, FiKey } from "react-icons/fi";
import {
  BiCategoryAlt,
  BiBell,
  BiCommentCheck,
  BiCommentX,
  BiCommentDots,
  BiCommentDetail,
} from "react-icons/bi";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import {
  IoPricetagsOutline,
  IoAccessibilityOutline,
  IoCartOutline,
} from "react-icons/io5";

const tabs = routes.find((page) => page.children).children;

let dashboardTabs = {};
Object.keys(tabs).map((key) => {
  let config = { ...tabs[key], showInNavbar: true };
  switch (key) {
    case "users":
      config.icon = <FiUsers />;
      break;
    case "permissions":
      config.icon = <FiKey />;
      break;
    case "subscriptions":
      config.icon = <BiBell />;
      break;
    case "roles":
      config.icon = <IoAccessibilityOutline />;
      break;
    case "categories":
      config.icon = <BiCategoryAlt />;
      break;
    case "promotions":
      config.icon = <RiProductHuntLine />;
      break;
    case "soldPromotions":
      config.title = "Sold Promotions";
      config.icon = <RiProductHuntLine />;
      break;
    case "currentInvoices":
      config.title = "Current invoices";
      config.icon = <LiaFileInvoiceDollarSolid />;
      break;
    case "invoices":
      config.title = "Issued invoices";
      config.icon = <LiaFileInvoiceDollarSolid />;
      break;
    case "collections":
      config.icon = <TfiLayoutSliderAlt />;
      break;
    case "attributes":
      config.icon = <IoPricetagsOutline />;
      break;
    case "businesses":
      config.icon = <MdOutlineBusinessCenter />;
      config.title = "Businesses";
      config.children = [
        {
          title: "Pending",
          url: `businesses/pending`,
          icon: <BiCommentDots />,
        },
        {
          title: "Approved",
          url: `businesses/approved`,
          icon: <BiCommentCheck />,
        },
        {
          title: "Rejected",
          url: `businesses/rejected`,
          icon: <BiCommentX />,
        },
      ];
      break;
    case "carts":
      config.icon = <IoCartOutline />;
      config.title = "carts";
      config.children = [
        {
          title: "Pending",
          url: `${key}/pending`,
          icon: <IoCartOutline />,
        },
        {
          title: "Success",
          url: `${key}/success`,
          icon: <IoCartOutline />,
        },
        {
          title: "Fail",
          url: `${key}/fail`,
          icon: <IoCartOutline />,
        },
      ];
      break;
    case "comments":
      config.icon = <BiCommentDetail />;
      config.title = "Comments";
      config.children = [
        {
          title: "Pending",
          url: `${key}/pending`,
          icon: <BiCommentDots />,
        },
        {
          title: "Approved",
          url: `${key}/approved`,
          icon: <BiCommentCheck />,
        },
        {
          title: "Rejected",
          url: `${key}/rejected`,
          icon: <BiCommentX />,
        },
      ];
      break;
    case "profile":
      config.icon = <FiUser />;
      break;
    default:
      break;
  }
  dashboardTabs[key] = { ...config };
});

const config = {
  axiosBaseUrl: process.env.REACT_APP_URL,
  googleTrackId: process.env.REACT_APP_GA_TRACK_ID,
  dashboardTabs,
  map: {
    position: { lat: 59.9281229, lng: 10.715143 },
  },
};

export default config;
