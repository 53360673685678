import { API } from "src/config/api";
import RayID from "rayid";

export const toFarsiNumber = (value = "") => {
  let nVal = value.toString();
  const enNum = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  const FaNum = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"];

  for (let i = 0, numbersLen = enNum.length; i < numbersLen; i++) {
    nVal = nVal.replace(new RegExp(enNum[i], "g"), FaNum[i]);
  }
  return nVal;
};

// export const numberWithCommas = (number) => {
//   if (!number) return;
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };
export const numberWithCommas = (number) => {
  if (!number) return;
  return number
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithDash = (number) => {
  if (!number) return;
  return number
    .toString()
    .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{4})+(?!\d))/g, "-");
};

export const currency = (value) => {
  const val = value || 0;
  return JSON.parse(val).toLocaleString("nb-NO", {
    style: "currency",
    currency: "NOK",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const isValidPhoneNumber = (value) => {
  const regex = new RegExp("^(\\+98|0)?9\\d{9}$");
  const result = regex.test(value);
  return result;
};

export const setApiHeaders = (obj) => {
  API.defaults.headers.common["ctx"] = JSON.stringify(obj);
};

export const shamsi2Miladi = (timeStamp) => {
  var parts = timeStamp.split(/\/| /);
  var jy, jm, jd;
  jy = parseInt(parts[0]);
  jm = parseInt(parts[1]);
  jd = parseInt(parts[2]);
  var sal_a, gy, gm, gd, days;
  jy += 1595;
  days =
    -355668 +
    365 * jy +
    ~~(jy / 33) * 8 +
    ~~(((jy % 33) + 3) / 4) +
    jd +
    (jm < 7 ? (jm - 1) * 31 : (jm - 7) * 30 + 186);
  gy = 400 * ~~(days / 146097);
  days %= 146097;
  if (days > 36524) {
    gy += 100 * ~~(--days / 36524);
    days %= 36524;
    if (days >= 365) days++;
  }
  gy += 4 * ~~(days / 1461);
  days %= 1461;
  if (days > 365) {
    gy += ~~((days - 1) / 365);
    days = (days - 1) % 365;
  }
  gd = days + 1;
  sal_a = [
    0,
    31,
    (gy % 4 === 0 && gy % 100 !== 0) || gy % 400 === 0 ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  for (gm = 0; gm < 13 && gd > sal_a[gm]; gm++) gd -= sal_a[gm];
  return new Date([gy + "/" + gm + "/" + gd] + " " + parts[3]);
};

export const convertArtoPr = (str) => {
  let string = str;
  const diff = {
    ة: "ه",
    ك: "ک",
    دِ: "د",
    بِ: "ب",
    زِ: "ز",
    ذِ: "ذ",
    شِ: "ش",
    سِ: "س",
    ى: "ی",
    ي: "ی",
    ئ: "ی",
    "٠": "۰",
    "١": "۱",
    "٢": "۲",
    "٣": "۳",
    "٤": "۴",
    "٥": "۵",
    "٦": "۶",
    "٧": "۷",
    "٨": "۸",
    "٩": "۹",
  };
  Object.entries(diff).map(
    ([key, value]) => (string = string.replaceAll(key, value))
  );
  return string;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const strFieldExtractor = (str) => {
  const array = [];
  var patt = /<\/?[a-z][^>]*>/gi;
  const match = str.match(patt);
  match &&
    match
      .filter((input) => input.includes("input") && input)
      .map((input, index) => {
        array.push({ input });
      });
  return array;
};

export const compareArrays = (first, second) => {
  return (
    first.every((e) => second.includes(e)) &&
    second.every((e) => first.includes(e))
  );
};

export const slugRegex = (data) => {
  const string = data || "";
  return string
    .replace(/[^a-zA-Z0-9]/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .split(/\s+/g)
    .join(" ")
    .replaceAll(" ", "-")
    .toLowerCase();
};

export const getCombineAttrs = () => {
  let attributes = {
    color: ["Red", "Blue"],
    sizes: ["Small", "Medium", "Large"],
  };
  let attrs = [];
  for (const [attr, values] of Object.entries(attributes))
    attrs.push(values.map((v) => ({ [attr]: v })));
  attrs = attrs.reduce((a, b) =>
    a.flatMap((d) => b.map((e) => ({ ...d, ...e })))
  );
  return attrs;
};

export const bufferToBase64 = (buffer) => {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:image/jpeg;base64,${window.btoa(binary)}`;
};

export const generateUrlBaseOnAttrs = (array) => {
  let queryString = "";
  array.map((key) => (queryString += `,${key}`));
  queryString = queryString.substring(1);
  queryString = `&attributes=${queryString}`;

  return queryString;
};

export const getSortObj = (sortId) => {
  console.log(sortId);
  const splittedCommentSort = sortId.split("_");
  const sort = splittedCommentSort[0];
  const dir = splittedCommentSort[1];
  return { sort, dir };
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error("File is required."));
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      resolve(base64String);
    };

    reader.readAsDataURL(file);
  });
};

const handleMonthAndDayZero = (num) => (num < 10 ? `0${num}` : num);

export const dateObjToISOString = (object) => {
  const dateObject = new Date(
    Date.UTC(object.year, object.month - 1, object.day, 0, 0, 0)
  );
  return dateObject.toISOString();
};

export const mapNavigationBaseString =
  "https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=";

export const ISOStringToDateObj = (isoString) => {
  const dateObject = new Date(isoString);

  return {
    day: dateObject.getUTCDate(),
    month: dateObject.getUTCMonth() + 1, // Months are zero-based, so add 1
    year: dateObject.getUTCFullYear(),
  };
};

export const flattenFilters = (filtersObj, columns) => {
  const filters = {};

  Object.entries(filtersObj)
    .filter(([key, value]) => typeof value === "string" && value !== "")
    .map(([key, value]) => (filters[key] = value));

  Object.entries(filtersObj)
    .filter(([key, value]) => value)
    .filter(
      ([key, value]) =>
        typeof value === "object" && Object.values(value)[0] !== undefined
    )
    .map(([key, value]) => {
      const entries = Object.entries(value);
      let objectKey;
      let objectValue;

      if (entries.length === 1) {
        const [nestedKey, nestedValue] = entries[0];
        if (nestedValue.hasOwnProperty("value") && nestedKey === "label") {
          objectKey = key;
          objectValue = nestedValue?.value;
        } else {
          objectKey = `${key}.${nestedKey}`;
          objectValue =
            typeof nestedValue === "object" ? nestedValue?.value : nestedValue;
        }
      } else {
        const valueField = entries.find(([nestedKey]) => nestedKey === "value");
        if (valueField) {
          objectKey = key;
          objectValue = valueField[1];
        }
      }

      filters[objectKey] = objectValue;
    });

  return filters;
};

export const ray = new RayID("lower");

export const intRay = new RayID("digit");
