const url = {
  auth: {
    login: "auth/login",
    register: "auth/register",
    forgotPassword: "auth/reset-password",
    resetPassword: "auth/new-password",
  },
  users: "users",
  promotions: "promotions",
  businesses: "businesses",
  categories: "categories",
  collections: "collections",
  permissions: "permissions",
  comments: "comments",
  roles: "roles",
  uploadFormFiles: "files",
  forms: "forms",
  attributes: "attributes",
  base: "base",
  invoices: "invoices",
  subscriptions: "subscriptions",
  specialPromotions: "promotions/specials",
  subscriptionsPromotions: "promotions/subscriptions",
  payments: "payments",
  carts: "carts",
};

export default url;
