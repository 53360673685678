export const containerStyles = {
  components: {
    Container: {
      baseStyle: {
        maxW: {
          sm: "95%",
          // md: "95%",
          // lg: "95%",
          // xl: "95%",
          "2xl": "94em",
        },
      },
    },
  },
};
