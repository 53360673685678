import { mode } from "@chakra-ui/theme-tools";

export const textStyles = {
  components: {
    Text: {
      baseStyle: {
        fontFamily: "regularBody",
      },
      baseStyle: (props) => ({
        color: mode("black", "lightGray.light")(props),
      }),
      variants: {
        pending: (props) => ({
          color: mode("white", "white")(props),
          bg: mode("darkGray.light", "darkGray.dark")(props),
        }),
        submit: (props) => ({
          color: mode("white", "white")(props),
          bg: mode("orange.light", "orange.dark")(props),
        }),
        reject: (props) => ({
          color: mode("white", "white")(props),
          bg: mode("red.light", "red.dark")(props),
        }),
        send: (props) => ({
          color: mode("white", "white")(props),
          bg: mode("green.light", "green.dark")(props),
        }),
      },
    },
  },
};
