import jwt_decode from "jwt-decode";
import _ from "underscore";

//config
import setAuthToken from "src/config/api/setAuthToken";
import { API, URL } from "src/config/api";

//helper
import { setApiHeaders } from "src/helper";

import {
  SET_CURRENT_USER,
  LOG_OUT_USER,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_USER_DATA,
  SET_APP_LOADING,
  EMPTY_CART,
} from "src/redux/constants";

export const setCurrentUser =
  (token = {}) =>
  async (dispatch) => {
    setAuthToken(token);
    const decoded = jwt_decode(token);
    try {
      const response = await API.get(`${URL.users}/${decoded.id}`);
      const userInfo = response.data;
      const userHasFormsPermissions = userInfo.role.permissions.find(
        ({ value }) => value === "forms"
      );

      if (userHasFormsPermissions) {
        try {
          const userDbFields = await API.get(`${URL.users}/db-fields`);
          localStorage.setItem(
            "userModelKeys",
            JSON.stringify(userDbFields.data)
          );
        } catch (error) {}
      }

      localStorage.setItem("userToken", token);

      setApiHeaders({
        userId: userInfo._id,
        path: response.config.headers.path || "/",
        role: userInfo.role.value,
      });
      dispatch({
        type: SET_CURRENT_USER,
        payload: userInfo,
      });
      return Promise.resolve(userInfo);
    } catch (err) {
      console.log(err);
      if (
        err.response.statusText === "Unauthorized" ||
        err.response.status === 401
      ) {
        dispatch(logoutUser());
      }
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response,
      });
    }
  };

export const registerUser =
  (data, loginAfterRegister = true) =>
  async (dispatch) => {
    try {
      const response = await API.post(URL.auth.register, data);
      if (loginAfterRegister) {
        response.then(() => dispatch(loginUser(data)));
      } else {
        return Promise.resolve(response);
      }
    } catch (err) {
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response,
      });
      return Promise.reject(err.response.data);
    }
  };

export const updateUser = (data, id) => async (dispatch) => {
  try {
    const response = await API.put(`${URL.users}/${id}`, data);
    dispatch({
      type: SET_USER_DATA,
      payload: response.data.data,
    });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });
    const response = await API.post(URL.auth.login, data);
    const { token } = response.data;
    await dispatch(setCurrentUser(token));
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
    return Promise.reject(err.response.data);
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: SET_APP_LOADING,
    payload: true,
  });
  localStorage.removeItem("userToken");
  localStorage.removeItem("userModelKeys");
  setAuthToken(false);
  dispatch({
    type: LOG_OUT_USER,
  });
  dispatch({
    type: EMPTY_CART,
  });
  dispatch({
    type: SET_APP_LOADING,
    payload: false,
  });
};
