import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { getAppData } from "src/redux/app/actions";
// import { getCart } from "src/redux/cart/actions";

//pages
import routes from "./routes";
import ProtectedRoute from "src/routes/protected-route";

//components
import Loader from "src/components/loader";
import ErrorPage from "src/components/404";

//loadable
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//helper
import { setApiHeaders } from "src/helper";

// css
import "react-day-picker/dist/style.css";

import ReactGA from "react-ga4";
import config from "src/config";
const TRACKING_ID = config.googleTrackId;

const LoadablePage = loadable(
  ({ directory, user, path }) => {
    setApiHeaders({
      userId: user?.data?._id,
      userRole: user?.data?.role?.value,
      path,
    });
    return pMinDelay(import(`src/pages/${directory}`), 0);
  },
  {
    cacheKey: (props) => props.directory,
  }
);

const getIndexRoute = routes.filter((route) => route.index === true);
export const indexRoute = getIndexRoute ? getIndexRoute[0] : routes[0];

const App = () => {
  ReactGA.initialize(TRACKING_ID);

  const dispatch = useDispatch();
  const { app, user } = useSelector((state) => ({
    app: state.app,
    user: state.user,
  }));

  const [router, setRouter] = useState(null);

  useEffect(() => {
    dispatch(getAppData());
    // dispatch(getCart());
  }, []);

  useEffect(() => {
    if (!app.loading) {
      setRouteConfig();
    }
  }, [app]);

  const setRouteConfig = () => {
    let routeConfig = routes;
    if (!user.isAuthenticated) {
      routeConfig = routeConfig.filter(
        (route) => route.private === user.isAuthenticated
      );
    }
    const routesList = generateRoutes(routeConfig);
    console.log(routesList);
    setRouter(createBrowserRouter(routesList));
  };

  const generateRoutes = (routesObj) => {
    const routeConfig = [];

    routesObj.map((route) => {
      const nestedRoute = route.hasOwnProperty("children");
      if (nestedRoute) {
        let path = `/${route.url}`;
        routeConfig.push({
          path,
          element: createRoute(path, route.directory, route.private),
          errorElement: <ErrorPage />,
        });

        Object.values(route.children)
          .filter(
            ({ key }) =>
              user &&
              user.data &&
              user.data.role &&
              user.data.role.permissions.map(({ value }) => value).includes(key)
          )
          .map((childRoute) => {
            let path = `/${route.url}/${childRoute.url}`;
            if (childRoute.hasOwnProperty("params")) {
              path = path + `/${childRoute.params}`;
            }
            const directory = `${route.directory}/pages/${childRoute.directory}`;
            routeConfig.push({
              path,
              element: createRoute(path, route.directory, route.private),
              children: [
                {
                  path,
                  element: createRoute(path, directory, route.private),
                },
              ],
              errorElement: <ErrorPage />,
            });
          });
      } else {
        let path = `/${route.url}`;
        if (route.hasOwnProperty("params")) {
          path = path + `/${route.params}`;
        }
        routeConfig.push({
          path,
          element: createRoute(path, route.directory, route.private),
          errorElement: <ErrorPage />,
        });
      }
    });
    return routeConfig;
  };

  const createRoute = (path, directory, isPrivate) => {
    let routeElement;
    if (isPrivate) {
      routeElement = (
        <ProtectedRoute>
          <LoadablePage
            path={path}
            user={user}
            fallback={<Loader />}
            directory={directory}
          />
        </ProtectedRoute>
      );
    } else {
      routeElement = (
        <LoadablePage
          user={user}
          path={path}
          fallback={<Loader />}
          directory={directory}
        />
      );
    }
    return routeElement;
  };

  return !router ? <Loader /> : <RouterProvider router={router} />;
};

export default App;
