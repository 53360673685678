import { API, URL } from "src/config/api";

export const getData = async (obj) => {
  let url = URL.businesses;

  try {
    const response = await API.get(url, { params: obj });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const getSingle = async (id) => {
  try {
    const response = await API.get(`${URL.businesses}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: 400 });
  }
};

export const createData = async (data) => {
  try {
    const response = await API.post(`${URL.businesses}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateData = async (id, data) => {
  try {
    const response = await API.put(`${URL.businesses}/${id}`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteData = async (id) => {
  try {
    const response = await API.delete(`${URL.businesses}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
