//packages
import _ from "underscore";

//constants
import { EMPTY_CART, SET_CART } from "src/redux/constants";

//services
import { createCart, getSingle, deleteData } from "src/services/api/carts";

export const getCart = () => async (dispatch) => {
  try {
    const cart = await getSingle("000000000000000000000000");
    dispatch({
      type: SET_CART,
      payload: cart,
    });
  } catch (error) {}
};
export const manageCart = (promotion, operation) => async (dispatch) => {
  try {
    const cart = await createCart({ promotion, operation });
    dispatch({
      type: SET_CART,
      payload: cart,
    });
    return Promise.resolve(cart);
  } catch (error) {
    return Promise.reject(error.err);
  }
};
export const emptyCart = (_id) => async (dispatch) => {
  try {
    await deleteData(_id);
    dispatch({
      type: EMPTY_CART,
    });
  } catch (error) {}
};
