import { EMPTY_CART, SET_CART } from "src/redux/constants";

const initialState = {
  items: [],
  user: "",
  totalPrice: 0,
  firstCheckout: null,
  status: "draft",
};

export default function (state = initialState, action) {
  const storedData = { ...state };
  const payload = action.payload;
  switch (action.type) {
    case SET_CART:
      return payload;
    case EMPTY_CART:
      return initialState;
    default:
      return state;
  }
}
